/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    pre: "pre",
    code: "code",
    strong: "strong",
    h3: "h3"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "rollupjs",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#rollupjs",
    "aria-label": "rollupjs permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Rollup.js"), "\n", React.createElement(_components.p, null, "SVGR provides an official ", React.createElement(_components.a, {
    href: "https://rollupjs.org"
  }, "rollup.js"), " plugin to import SVG as React components."), "\n", React.createElement("carbon-ad"), "\n", React.createElement(_components.h2, {
    id: "install",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#install",
    "aria-label": "install permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Install"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-bash"
  }, "npm install --save-dev @svgr/rollup\n# or use yarn\nyarn add --dev @svgr/rollup\n")), "\n", React.createElement(_components.h2, {
    id: "usage",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#usage",
    "aria-label": "usage permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Usage"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "rollup.config.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import svgr from '@svgr/rollup'\n\nexport default {\n  plugins: [svgr()],\n  input: 'src/main.js',\n  output: {\n    file: 'bundle.js',\n    format: 'cjs',\n  },\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Your code")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import Star from './star.svg'\n\nconst App = () => (\n  <div>\n    <Star />\n  </div>\n)\n")), "\n", React.createElement(_components.h2, {
    id: "options",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#options",
    "aria-label": "options permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Options"), "\n", React.createElement(_components.p, null, "SVGR let you specify options in a runtime config file like ", React.createElement(_components.code, null, "svgr.config.js"), " or directly in the rollup.js plugin:"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "rollup.config.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import svgr from '@svgr/rollup'\n\nexport default {\n  plugins: [svgr({ icon: true })],\n  input: 'src/main.js',\n  output: {\n    file: 'bundle.js',\n    format: 'cjs',\n  },\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://react-svgr.com/docs/options/"
  }, "SVGR options reference"), " describes all options available."), "\n", React.createElement(_components.h2, {
    id: "using-with-rollupplugin-url",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#using-with-rollupplugin-url",
    "aria-label": "using with rollupplugin url permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Using with ", React.createElement(_components.code, null, "@rollup/plugin-url")), "\n", React.createElement(_components.p, null, "It is possible to use it with ", React.createElement(_components.a, {
    href: "https://github.com/rollup/plugins/tree/master/packages/url"
  }, React.createElement(_components.code, null, "@rollup/plugin-url")), "."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "rollup.config.js")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import url from '@rollup/plugin-url'\nimport svgr from '@svgr/rollup'\n\nexport default {\n  plugins: [url(), svgr({ icon: true })],\n  input: 'src/main.js',\n  output: {\n    file: 'bundle.js',\n    format: 'cjs',\n  },\n}\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Your code")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "import starUrl, { ReactComponent as Star } from './star.svg'\n\nconst App = () => (\n  <div>\n    <img src={starUrl} alt=\"star\" />\n    <Star />\n  </div>\n)\n")), "\n", React.createElement(_components.p, null, "The named export defaults to ", React.createElement(_components.code, null, "ReactComponent"), ", but can be customized with the ", React.createElement(_components.code, null, "namedExport"), " option."), "\n", React.createElement(_components.p, null, "Please note that by default, ", React.createElement(_components.code, null, "@svgr/rollup"), " will try to export the React Component via default export if there is no other plugin handling svg files with default export. When there is already any other plugin using default export for svg files, ", React.createElement(_components.code, null, "@svgr/rollup"), " will always export the React component via named export."), "\n", React.createElement(_components.p, null, "If you prefer named export in any case, you may set the ", React.createElement(_components.code, null, "exportType"), " option to ", React.createElement(_components.code, null, "named"), "."), "\n", React.createElement(_components.h3, {
    id: "use-your-own-babel-configuration",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#use-your-own-babel-configuration",
    "aria-label": "use your own babel configuration permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Use your own Babel configuration"), "\n", React.createElement(_components.p, null, "By default, ", React.createElement(_components.code, null, "@svgr/rollup"), " applies a babel transformation with ", React.createElement(_components.a, {
    href: "https://github.com/gregberge/svgr/blob/main/packages/rollup/src/index.ts"
  }, "optimized configuration"), ". In some case you may want to apply a custom one (if you are using Preact for an example). You can turn off Babel transformation by specifying ", React.createElement(_components.code, null, "babel: false"), " in options."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "{\n  plugins: [svgr({ babel: false })]\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
